<template>
  <section class="container pb-8 cs-nav" data-style="3">
    <div class="row">
      <!-- 分類導覽 -->
      <aside class="col-12 col-md-3 col-lg-2" id="filter-bar">
        <ul class="nav flex-column nav-pills d-none d-md-block" aria-orientation="vertical">
          <li
            v-for="item in NewsCategorys"
            :key="item.newsCategoryId"
            :data-filter="item.newsCategoryId"
            :class="[
              'ul-li-block',
              'nav-link',
              'fa',
              { active: item.newsCategoryId === Pagination.data.newsCategoryId },
            ]"
            @click="GetList(item.newsCategoryId)"
          >
            {{ item.newsCategoryName }}
          </li>
        </ul>
        <div class="dropdown cs-select mb-6 d-md-none" data-style="1">
          <button
            type="button"
            class="btn dropdown-toggle select__btn"
            id="dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-offset="10,20"
          >
            {{ NowCategoryName }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuOffset">
            <li
              v-for="item in NewsCategorys"
              :key="item.newsCategoryId"
              :data-filter="item.newsCategoryId"
              :class="['dropdown-item', 'select__option']"
              @click="GetList(item.newsCategoryId)"
            >
              {{ item.newsCategoryName }}
            </li>
          </div>
        </div>
      </aside>
      <!-- 主要內容 -->
      <div class="col-12 col-md-9 col-lg-10">
        <div class="row pb-6">
          <div class="cs-table" data-style="8">
            <div class="table__head">
              <div class="table__head--date">日期</div>
              <div class="table__head--type">分類</div>
              <div class="table__head--title">標題</div>
            </div>
            <ul id="filter-list">
              <li
                v-for="item in PageInfo.data"
                :key="item.newsCategoryId"
                :data-filter="item.newsCategoryId"
                class="table__item"
                :data-filter-class="Pagination.data.newsCategoryId"
              >
                <router-link class="text-dark" :to="`/news/${item.newsId}`">
                  <span class="table__item--date">{{
                    item.newsStartDate | toTaipei | moment('YYYY-MM-DD')
                  }}</span>
                  <span class="table__item--type">{{ item.newsCategoryName }}</span>
                  <h6 class="table__item--title">{{ item.newsSubject }}</h6>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- 分頁 開始 -->
        <!-- 分頁 -->
        <Pagination
          @GetList="GetList(Pagination.data.newsCategoryId)"
          :page.sync="Pagination.pageIndex"
          :page-row="Pagination.pageSize"
          :total-count="PageInfo.count"
        />
        <!-- 分頁 結束 -->
      </div>
    </div>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { GetNewsCategoryAll, GetNewsList } from '@/api/NewsApi';

export default {
  components: {
    Pagination,
  },
  data() {
    // 建立你的初始化 model data
    return {
      Pagination: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: { newsCategoryId: 0 },
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
      NewsCategorys: [],
    };
  },
  computed: {
    NowCategoryName() {
      const data = this._.findLast(this.NewsCategorys, [
        'newsCategoryId',
        this.Pagination.data.newsCategoryId,
      ]);
      if (data !== undefined) return data.newsCategoryName;
      return '';
    },
  },
  // 當有設定keep的時候，返回到此頁面會執行這個，不會執行created()，所以這邊就不用放created()
  async activated() {
    if (!this.$route.meta.isBack) {
      // 不是從指定頁面返回的話，進行初始化設定
      this.Pagination = {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: { newsCategoryId: 0 },
      };

      this.PageInfo = {
        count: 0, // api給的總筆數
        data: [],
      };
    }
    // 取得選單
    await this.GetNewsCategoryList();
    this.GetList(this.Pagination.data.newsCategoryId);
  },
  mounted() {},
  methods: {
    GetNewsCategoryList() {
      return GetNewsCategoryAll().then((response) => {
        if (response.data.statusCode === 0) {
          this.NewsCategorys = response.data.data;
          if (this.NewsCategorys.length > 0) {
            if (this.Pagination.data.newsCategoryId === 0) {
              this.Pagination.data.newsCategoryId = this.NewsCategorys[0].newsCategoryId;
            }
          }
        }
      });
    },
    GetList(categoryId) {
      if (this.Pagination.data.newsCategoryId !== categoryId) {
        this.Pagination = {
          pageIndex: 1, // 預設從第幾頁開始
          pageSize: 6, // 預設每頁幾筆
          data: { newsCategoryId: 0 },
        };
      }
      this.Pagination.data.newsCategoryId = categoryId;
      GetNewsList(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;

          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList(categoryId);
          }
        }
      });
    },
  },
};
</script>

<style>
.ul-li-block {
  display: block;
}
</style>
